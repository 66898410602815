import React from 'react';
import CustomNavbar from './components/CustomNavbar';
import HomeSection from './components/HomeSection';
import AboutSection from './components/AboutSection';
import TeamSection from './components/TeamSection';
import ContactSection from './components/ContactSection';
import Footer from './components/Footer';
import { inject } from '@vercel/analytics';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  inject();
  return (
    <div>
      <CustomNavbar />
      <HomeSection />
      <AboutSection />
      <TeamSection />
      <ContactSection />
      <Footer />
    </div>
  );
}

export default App;
