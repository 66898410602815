import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
// import snrgy_logo from './snrgy_icon.png';
import snrgy_logo from './snrgy-logo.svg';

const CustomNavbar = () => {
  return (
    <Navbar bg="light" variant="light" expand="lg" fixed="top" style={{ padding: '1rem' }}>
      <Navbar.Brand href="#home">
        <img
          src={snrgy_logo}
          width="200"
          // height="30"
          className="d-inline-block align-top"
        />{' '}
        {/* <span className='site-title'>Snrgy Solutions</span> */}
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav" style={{ justifyContent: 'flex-end' }}>
        <Nav>
          <Nav.Link href="#about">About</Nav.Link>
          <Nav.Link href="#team">Team</Nav.Link>
          <Nav.Link href="#contact">Contact</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default CustomNavbar;
