// AboutSection.js
import React from 'react';
import './AboutSection.css';
import './Section.css';

const AboutSection = () => (
    <div id="about" className="about-section">
        <h1 className="section-title">About Us</h1>
        <div className="container">
            <div className="row">
                <div className="col-md-6 square q1">
                    <div className="content">
                        <h2>What We Do</h2><br />
                        <p>We provide <span className='avenir-bold' style={{ color: "#297BE6" }}>Business to Business provider services.</span> This model helps manage and optimize between healthcare entity workforce assets by reducing expenses, increasing productivity, and providing supplemental incentives to Providers. This unique partnership provides a benefit for the Sourcing entity, Receiving entity, and the Providers.</p>
                    </div>
                </div>
                <div className="col-md-6 square q2">
                    <div className="content">
                        <h2>How We Do It</h2><br />
                        <p>Snrgy utilizes highly qualified providers from <span className='avenir-bold'>Sourcing</span> healthcare entities and places them in <span className='avenir-bold'>Receiving</span> healthcare entities who may not have otherwise been able to provide that service. This creates a Win-Win-Win by not disrupting the provider’s productivity (while allowing them to earn more income), reducing staffing costs at the Sourcing entity, and providing needed services at the Receiving entity.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default AboutSection;
