import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './TeamSection.css';
import zachImg from './zach.jpg';
import leeImg from './lee.jpeg';
import ilanImg from './ilan.PNG';
import './Section.css';

const TeamSection = () => {
    return (
        <div id="team" className="team-section">
            <Container>
                <h1 className="section-title">Our Team</h1>
                <Row className="justify-content-md-center">
                    <Col xs={12} md={4}>
                        <div className="team-member">
                            <img src={zachImg} alt="Team Member 1" />
                            <h3>Zach Maher</h3>
                            <p>Chief Executive Officer</p>
                        </div>
                    </Col>
                    <Col xs={12} md={4}>
                        <div className="team-member">
                            <img src={leeImg} alt="Team Member 2" />
                            <h3>Lee Ponsky</h3>
                            <p>Chief Medical Officer</p>
                        </div>
                    </Col>
                    <Col xs={12} md={4}>
                        <div className="team-member">
                            <img src={ilanImg} alt="Team Member 3" />
                            <h3>Ilan Ponsky</h3>
                            <p>Chief Technology Advisor</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default TeamSection;
