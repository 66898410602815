import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useForm, ValidationError } from '@formspree/react';
import './ContactSection.css';
import './Section.css';

const ContactSection = () => {
    const [state, handleSubmit] = useForm("myyqonpv");

    return (
        <div id="contact" className="contact-section">
            <Container>
                <Row>
                    <Col className="text-center">
                        <h1 className="section-title contact">Contact</h1>
                    </Col>
                </Row>
                <Row>
                    <Col md={{ span: 6, offset: 3 }}>
                        {state.succeeded ? (
                            <div className="contact-form text-center">
                                <p>Thank you for the message! Someone will reach out to you promptly.</p>
                            </div>
                        ) : (
                            <form className="contact-form" onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label htmlFor="email">Email Address</label>
                                    <input id="email" type="email" name="email" className="form-control" />
                                    <ValidationError prefix="Email" field="email" errors={state.errors} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="message">Message</label>
                                    <textarea id="message" name="message" className="form-control" />
                                    <ValidationError prefix="Message" field="message" errors={state.errors} />
                                </div>
                                <div className="text-center">
                                    <button type="submit" className="btn btn-primary" disabled={state.submitting}>
                                        Submit
                                    </button>
                                </div>
                            </form>
                        )}
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default ContactSection;
