import React from 'react';
import './HomeSection.css';
import backgroundImg from './backgroundlight1.png';

const HomeSection = () => {
  return (
    <div id="home" className="home-section" style={{backgroundImage: `url(${backgroundImg})`}}>
      <div className="text-overlay">
        <h1>Where Synergy Meets Strategy in <span className='avenir-bold' style={{color: "#297BE6"}}>Healthcare Excellence</span></h1>
      </div>
    </div>
  );
};

export default HomeSection;
