import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <div className="footer-section">
            <p>Copyright © 2024 Snrgy Solutions</p>
        </div>
    );
};

export default Footer;
